import { Col } from 'react-bootstrap';
import styled from 'styled-components';

export const MainContainer = styled.div`
  background: #fff;
  color: #6e2b8b;
  font-family: 'Figtree-Regular', arial, sans-serif;
  font-size: 15px;
  line-height: 130%;
  letter-spacing: 0.02em;
`;

export const InnerContainer = styled.div`
  width: 860px;
  margin: 0 auto;
  padding: 50px 0 0 0;
  position: relative;
  @media (max-width: 991px) {
    width: 100%;
    margin: 0;
    padding: 30px 30px 0 30px;
  }
`;

export const ColumnStyle = styled(Col)`
  padding: 20px 40px 40px 40px !important;
  text-align: center;
  @media (max-width: 991px) {
    height: auto;
    padding: 20px 10% !important;
    font-size: 20px;
    line-height: 25px;
    padding-bottom: 60px !important;
    border-bottom: 1px solid #ededed;
  }
`;
