import loadable from '@loadable/component';
import React from 'react';
import { Row } from 'react-bootstrap';
import { sectionList } from '../../constants/queryFragments';
import * as Styled from './slpPropaneContentListStyle';

const SlpPropaneContentList = ({ sectionData }) => {
  return (
    <Styled.MainContainer>
      <Styled.InnerContainer>
        {sectionData && (
          <>
            <Row className="g-0">
              {sectionData?.list &&
                sectionData.list.map((section, index) => {
                  if (section?.type && sectionList.includes(section?.type)) {
                    const SectionComponent = loadable(() =>
                      import(`../../components/${section.type}/${section.type}`)
                    );
                    return (
                      <Styled.ColumnStyle xs={12} lg key={index}>
                        <SectionComponent key={index} sectionData={section} />
                      </Styled.ColumnStyle>
                    );
                  }
                })}
            </Row>
          </>
        )}
      </Styled.InnerContainer>
    </Styled.MainContainer>
  );
};

export default SlpPropaneContentList;
